.iconButton {
  cursor: pointer;
}

.centerText {
  text-align: center;
}

.swal2-container {
  z-index: 10000 !important;
}

.tableDiv {
  width: 1px !important;
}

.dropzone {
  border-radius: 0% !important;
  /* height: 70px !important;
  width: 80% !important; */
}

.react-datepicker__day--highlighted-custom-0 {
  color: red !important;
  opacity: 0.6 !important;
}

.react-datepicker__day--highlighted-custom-1 {
  color: purple !important;
  opacity: 0.6 !important;
}

.react-datepicker__day--highlighted-custom-2 {
  color: green !important;
  opacity: 0.6 !important;
}

.react-datepicker__day--highlighted-custom-3 {
  color: cyan !important;
  opacity: 0.6 !important;
}

.react-datepicker__day--highlighted-custom-4 {
  color: orange !important;
  opacity: 0.6 !important;
}
