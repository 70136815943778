.dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  overflow-x: hidden;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.dzu-dropzone{
  overflow-y: hidden;
  min-height: 80px !important;
}

.dzu-inputLabel{
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 0;
  color: orange;
}

.dzu-previewContainer {
  padding: 0px 5px 0px 5px;
}

.btnFile {
  background-color: transparent;
	border-color: #777!important;
	color: #777;
	text-align: left;
}
