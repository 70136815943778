.cornerButtonClose {
  position: absolute;
  top: -15px;
  right: -15px;
  color: red;
}

.buttonAddPhase {
  margin-right: 30px;
}

.sectionCard {
  margin-right: 15px;
  margin-top: 15px;
}

.addSection {
  height: 120px;
  text-align: center;
  color: green;
  padding: 5%;
}

.phasesEditHeader {
  padding-top: 30px;
}

.phasesAdd {
  margin-left: 30%;
}
