.createProject {
  margin: 10px 30px 20px 30px;
}

.createProjectTeam {
  margin: 30px 100px 20px 100px;
}

.createProjectTeamButton {
  text-align: center;
  margin: 10;
  color: red;
}
